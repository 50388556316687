import React from 'react';
import { useNavigate } from 'react-router-dom';
import kpostLogo from "../../Assets/ksmaclogo.png";
import { CCol } from "@coreui/react";
import ksmaccL from "../../Assets/ksmaclogo.png";
import { RiArrowDropUpLine, RiArrowDropDownLine } from "react-icons/ri";
const Footer = ({
    openForm,
    shoq,
    closeForm,
    KpostIframe
}) => {

    const navigate = useNavigate();

    return (
        <>
        <CCol md="12" className="mob_web_display_none">
            <CCol md="10" className={"float-left "}>
                <div>
                <div className="FooterAlign">
              <div className="d-flex flex-row px-4">
                      <div>
                        <span className="footpower">Powered by </span>
                        <span className="footyou">UEducate</span>
                      </div>
                      </div>
                      <div className="d-flex flex-row px-4">
                  <div className="allfoot">©2025 All rights reserved</div>
                      </div>
                      </div>

                      <div onClick={ () => {
                        navigate("/ksmacc")
                      } }
                      className={"float-right 123"}>
                        <img src= {kpostLogo} />
                      </div>
                      </div>
            </CCol>
        </CCol>

        <div style={{
            // backgroundColor: "red",
            // position: "fixed",
            bottom: "0px",
            width: "100%"
        }}>
        <CCol md="12" className="WebShownDesign">

        <CCol md="5" className={"float-left webFoot"}>
            <div className="footerdash">
              <div className="d-flex flex-row">
                  <div className="paddPower">
                    <span className="footpower">Powered by </span>
                    <span className="footyou">UEducate</span>
                  </div>
                  </div>
              </div>
        </CCol>

        <CCol md="4" className={"float-left webFoot"}>
            <div className="footerdash">
              <div className="d-flex flex-row padd2022">
                <div>
              <div className="allfoot">©2025 All rights reserved</div>
              </div>
                  </div>
              </div>
        </CCol>

        <CCol md="3" className={"float-left webFoot"}>

        </CCol>

        </CCol>

        </div>
        </>
  );

};

export default Footer;

